<template>
  <y-layout-body-menu :menu="[
      {title:'话术分类',path:'/card/script-sys/cate'},
      {title:'话术列表',path:'/card/script-sys/list'},
  ]">
    <router-view></router-view>
  </y-layout-body-menu>
</template>

<script>
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";

export default {
  name: "index",
  components: {YLayoutBodyMenu},
}
</script>

<style scoped>

</style>